import { React, useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { HiChevronDown } from "react-icons/hi";
import moment from "moment";

const getStatus = (status) => {
  if (status === "1") return "success";
  if (status === "2") return "failed";
  return "pending";
};

const purchaseHistoryTableModel = (onDetailSeek) => [
  {
    name: "Purchase No.",
    cell: (row) => row.purchase_number,
  },
  {
    name: "Packages",
    cell: (row) => row.items?.packages?.length || 0,
    center: true,
  },

  {
    name: "Date of Purchase",
    cell: (row) => moment(new Date(row.date_added)).format("DD MMM, YYYY"),
    center: true,
  },

  {
    name: "Time of Purchase",
    cell: (row) => moment(new Date(row.date_added)).format("hh:mm A"),
    center: true,
  },

  {
    name: "Status",
    cell: (row) => (
      <span className={`purchase__status ${getStatus(row.status)}`}>
        {getStatus(row.status)}
      </span>
    ),
    center: true,
  },
  {
    name: "Total Amount",
    cell: (row) => <span>AED {row.total}</span>,
    center: true,
  },
  {
    name: "",
    cell: (row) => (
      <button className="header_menu__item" onClick={() => onDetailSeek(row)}>
        Details
      </button>
    ),
  },
];

const PurchaseHistoryTable = ({ purchaseHistory = [] }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [purchaseHis, setPurchaseHis] = useState([]);
  const [dateAnchorEl, setDateAnchorEl] = useState(null);
  const [dateBtnText, setDateBtnText] = useState("Today");
  const dateAnchorElId = "date";
  const [dateRange, setDateRange] = useState(null);
  const [intialiDateRange, setInitialDateRange] = useState({});
  const [dropDownValues] = useState([
    {
      id: "All",
      value: "0",
    },
    {
      id: "Success",
      value: "1",
    },
    {
      id: "Failed",
      value: "2",
    },
  ]);
  const [dropDownValue, setDropDownValue] = useState(dropDownValues[0]);

  const onDetailSeek = (row) => {
    history.push("/purchase-details/" + row.purchase_number);
  };

  useEffect(() => {
    purchaseHistory = purchaseHistory.filter((purchase) =>
      purchase.items?.hasOwnProperty("packages")
    );
    setPurchaseHis(Array.prototype.reverse.call(purchaseHistory));
  }, [purchaseHistory]);

  const handleStatusChange = (value) => {
    setDropDownValue(
      dropDownValues.find((dropdown) => dropdown.value === value)
    );
  };

  const handleDateChange = (date) => {
    let startDateStr = moment(date.startDate).format("DD/MM/YYYY");
    let endDateStr = moment(date.endDate).format("DD/MM/YYYY");
    setDateRange(date);
    let btnText;
    if (date.startDate) {
      if (startDateStr === endDateStr) {
        btnText = startDateStr;
      } else {
        btnText = (
          <span>
            {startDateStr} <span className="op-5">to</span> {endDateStr}
          </span>
        );
      }
    } else {
      btnText = "Today";
    }
    setDateBtnText(btnText);
    setPopoverOpen(!popoverOpen);
  };

  const handleFilterData = () => {
    var data = Array.prototype.reverse.call(purchaseHistory);
    if (dropDownValue.value != "0") {
      data = data.filter((purchase) => purchase.status === dropDownValue.value);
    }
    if (dateRange) {
      let start = moment(dateRange.startDate).format(moment.HTML5_FMT.DATE);
      let end = moment(dateRange.endDate).format(moment.HTML5_FMT.DATE);
      if (start === end) {
        data = data.filter(
          (purchase) =>
            moment(purchase.date_added).format(moment.HTML5_FMT.DATE) === start
        );
      } else {
        const start = moment(dateRange.startDate).subtract(1, "day");
        const end = moment(dateRange.endDate).subtract(1, "day");
        data = data.filter((purchase) =>
          moment(purchase.date_added, "YYYYMMDD").isBetween(start, end)
        );
      }
    }
    setPurchaseHis(data);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          marginBottom: "3%",
          width: "50%",
          justifyContent: "space-between",
        }}
      >
        <Dropdown isOpen={open} toggle={() => setOpen(!open)}>
          <DropdownToggle caret>{dropDownValue?.id}</DropdownToggle>
          <DropdownMenu onClick={(e) => handleStatusChange(e.target.value)}>
            {dropDownValues.map((dropdown) => (
              <DropdownItem value={dropdown.value}>{dropdown.id}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        <Button
          aria-controls={popoverOpen}
          aria-haspopup="true"
          onClick={(event) => setPopoverOpen(!popoverOpen)}
          variant="outlined"
          className="mr-4"
        >
          {dateBtnText}
        </Button>
        <DateRangePicker
          open={popoverOpen}
          toggle={() => setPopoverOpen(!popoverOpen)}
          initialDateRange={intialiDateRange}
          onChange={(date) => {
            handleDateChange(date);
          }}
        />
        <button className="header_menu__item" onClick={handleFilterData}>
          Get Data
        </button>
      </div>
      <DataTable
        highlightOnHover
        onRowClicked={(row) => onDetailSeek(row)}
        columns={purchaseHistoryTableModel(onDetailSeek)}
        data={purchaseHis}
      />
    </div>
  );
};

export default PurchaseHistoryTable;
