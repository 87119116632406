import { applyMiddleware, compose, createStore } from "redux";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";
import CryptoJS from "crypto-js";
import { DEVELOPMENT } from "../constants";

let mode = DEVELOPMENT;
const saveToCookies = (state) => {
  let serializedUid = "";
  if (mode != DEVELOPMENT) {
    serializedUid = CryptoJS.AES.encrypt(
      JSON.stringify(state.authUser),
      "my-secret-key"
    ).toString();
  }
  // else {
  //   serializedUid = JSON.stringify(state.authUser);
  // }
  document.cookie = `authToken=${state.authUser.uid};path=/;`;
  document.cookie = `userDetails=${JSON.stringify(
    state.authUser.userDetails
  )};path=/;`;
  // localStorage.setItem("authToken", serializedUid);
};

const checkCookies = () => {
  const serializedUid = getCookie("authToken");
  const serializedUser = getCookie("userDetails");
  // const serializedUid = localStorage.getItem("authToken");
  if (serializedUid === null) return undefined;
  let authUser = "";
  if (mode != DEVELOPMENT) {
    authUser = JSON.parse(
      CryptoJS.AES.decrypt(serializedUid, "my-secret-key").toString(
        CryptoJS.enc.Utf8
      )
    );
  } else
    authUser = {
      uid: serializedUid,
      userDetails: JSON.parse(serializedUser),
    };
  return {
    authUser,
  };
};

const getCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(
  rootReducer,
  checkCookies(),
  composeEnhancers(applyMiddleware(thunk))
);
store.subscribe(() => saveToCookies(store.getState()));
export default store;
