import { AUTH_LOADING, AUTH_NOTIFICATION } from "../types";

const initState = {
    loading: false,
    notification: ''
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case AUTH_LOADING:
            return {
                ...state,
                loading: action.payload,
            }
        case AUTH_NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export default authReducer