import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDayBookingList } from "../store/actions/discoverActions";
import DataTable from "react-data-table-component";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Spinner } from "reactstrap";
import DateFnsUtils from "@date-io/date-fns";
import { NavLink } from "react-router-dom";
import { IoFastFoodOutline, IoBus, IoTimeOutline } from "react-icons/io5";

const DayBooking = () => {
  const [dayBookingList, setDayBookingList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.authUser.uid);

  const getStatus = (status) => {
    if (status === "1") return "success";
    if (status === "2") return "failed";
    return "pending";
  };

  const dayBookingColumns = [
    {
      name: "Purchase Number",
      selector: (row) => row.purchase_number,
      center: true,
    },
    {
      name: "Order Number",
      selector: (row) => row.order_number,
      center: true,
    },
    {
      name: "Package Name",
      cell: (row) => row.package_name,
      center: true,
    },
    {
      name: "Attendees",
      cell: (row) => Number(row.adult_qty) + Number(row.child_qty),
      center: true,
    },
    {
      name: "Selected Date",
      cell: (row) => row.selected_date,
      center: true,
    },
    {
      name: "Time Slot",
      cell: (row) => row.order_timeslot,
      center: true,
    },
    {
      name: "Redeem Status",
      cell: (row) => (
        <span className={`purchase__status ${getStatus(row.redeem)}`}>
          {getStatus(row.redeem)}
        </span>
      ),
      center: true,
    },
    {
      name: "Contains",
      cell: (row) => (
        <>
          {row.sub_package === "1" && (
            <div className="d-flex justify-content-between px-2">
              {row.mealData.map((meal) => (
                <div className="d-flex justify-content-start">
                  <span className="my-2 me-2 item__details">
                    <IoFastFoodOutline size={16} />
                  </span>
                  {/* <span className="my-2 me-2 item__details">
                    {meal.meal_qty}
                  </span> */}
                </div>
              ))}
              {typeof row.transportData === "object" && (
                <div
                  className="d-flex justify-content-start"
                  style={{ marginLeft: "6px" }}
                >
                  <span className="my-2 me-2 item__details">
                    <IoBus size={16} />
                  </span>
                </div>
              )}
              {Number(row.adult_qty) > 0 && (
                <div
                  className="d-flex justify-content-start"
                  style={{ marginLeft: "6px" }}
                >
                  <span className="my-2 me-2 item__details">
                    <i class="fa fa-user" aria-hidden="true"></i>
                  </span>
                  {/* <span className="my-2 me-2 item__details">
                      {subpackage.adult_qty}
                    </span> */}
                </div>
              )}
              {Number(row.child_qty) > 0 && (
                <div
                  className="d-flex justify-content-start"
                  style={{ marginLeft: "6px" }}
                >
                  <span className="my-2 me-2 item__details">
                    <i class="fa fa-child" aria-hidden="true"></i>
                  </span>
                  {/* <span className="my-2 me-2 item__details">
                      {subpackage.child_qty}
                    </span> */}
                </div>
              )}
            </div>
          )}
        </>
      ),
      center: true,
    },
    {
      name: "",
      cell: (row) => (
        <NavLink to={`/order-details/${row.order_number}`}>
          <button className="header_menu__item" style={{ width: "80px" }}>
            <span style={{ fontSize: 12 }}>Details</span>
          </button>
        </NavLink>
      ),
    },
  ];

  useEffect(() => {
    const date = moment(selectedDate).format("YYYY-MM-DD");
    getDayBookingList(setLoading, token, date, (response) => {
      console.log(response);
      setDayBookingList(response);
    });
  }, [selectedDate]);

  return (
    <div className="payment__container">
      <h4 className="mt-5 mb-4">Day Booking</h4>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className="mb-3"
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          value={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          size="small"
        />
      </MuiPickersUtilsProvider>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15%",
          }}
        >
          <Spinner className="package-spinner" />
        </div>
      ) : (
        <DataTable columns={dayBookingColumns} data={dayBookingList} />
      )}
    </div>
  );
};

export default DayBooking;
