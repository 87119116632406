import {
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  FETCH_PROFILE_FAILED,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_REQUESTED,
  UPDATE_PROFILE_REQUESTED,
  UPDATE_PROFILE_END,
  AUTH_LOGOUT,
} from "../types";

const initState = {
  uid: null,
  userDetails: {
    mobile: "",
    name: "",
    email: "",
    id: "",
  },
  profileFetchStatus: "idle",
  profileUpdateStatus: "idle",
};

const authUser = (state = initState, action) => {
  switch (action.type) {
    case AUTH_LOGOUT:
      return initState;
    case LOGIN_SUCCESS:
      return {
        ...state,
        uid: action.payload,
      };
    case LOGIN_FAIL:
      localStorage.clear();
      return {
        ...state,
        uid: null,
      };
    case FETCH_PROFILE_REQUESTED:
      return {
        ...state,
        profileFetchStatus: "requested",
      };
    case FETCH_PROFILE_FAILED:
      return {
        ...state,
        profileFetchStatus: "failed",
        userDetails: {
          mobile: "",
          name: "",
          email: "",
          id: "",
        },
      };
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        profileFetchStatus: "success",
        userDetails: action.payload,
      };
    case UPDATE_PROFILE_REQUESTED:
      return {
        ...state,
        profileUpdateStatus: "requested",
      };
    case UPDATE_PROFILE_END:
      return {
        ...state,
        profileUpdateStatus: "idle",
      };
    default:
      return {
        ...state,
      };
  }
};
export default authUser;
