import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import PurchaseHistoryTable from "../components/PurchaseHistoryTable";
import { getPurchaseHistory } from "../store/actions/discoverActions";
import { notifyError } from "../utils/notifyToast";

const PurchaseHistory = ({ purchaseHistory }) => {
  const [pHistory, setPHistory] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const token = useSelector((state) => state.authUser.uid);

  useEffect(() => {
    if (purchaseHistory) {
      setPHistory(purchaseHistory);
      return;
    }
    dispatch(
      getPurchaseHistory(setLoading, token, setPHistory, () => {
        notifyError("Could not load purchase history. Reload.");
      })
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div className="payment__container">
      <h5 className="mt-5 mb-5">Purchase History</h5>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15%",
          }}
        >
          <Spinner className="package-spinner" />
        </div>
      ) : (
        <PurchaseHistoryTable purchaseHistory={pHistory} />
      )}
    </div>
  );
};

export default PurchaseHistory;
