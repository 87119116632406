import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getDayClosureData,
  saveDayClosure,
} from "../store/actions/discoverActions";
import { useHistory } from "react-router-dom";
import { notifySuccess } from "../utils/notifyToast";
import { Spinner } from "reactstrap";

function DayClosure() {
  const [dayClosureData, setDayClosureData] = useState();
  const [loading, setLoading] = useState(false);
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));
  const history = useHistory();

  useEffect(() => {
    getDayClosureData(token, setLoading, (response) => {
      setDayClosureData(response);
    });
  }, []);

  const handleReportGenerate = () => {
    saveDayClosure(token, (response) => {
      notifySuccess(response.msg);
      history.push("/day-closure-history");
    });
  };

  return (
    <>
      <div className="m-5 pb-5">
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <Spinner className="package-spinner" />
          </div>
        )}
        {dayClosureData && (
          <div
            className="dayclosure__table"
            dangerouslySetInnerHTML={{
              __html: dayClosureData,
            }}
          ></div>
        )}
        <button
          className="header_menu__item"
          style={{ marginTop: "1rem" }}
          onClick={handleReportGenerate}
        >
          Day Closure
        </button>
      </div>
    </>
  );
}

export default DayClosure;
