import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import POS from './layouts/POS';
import Auth from './layouts/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import routes from './routes';

function App() {
	return (
		<>
			<BrowserRouter>
				<Switch>
					{routes.map((route, idx) => {
						switch (route.layout) {
							case 'pos':
								return (
									<Route exact path={route.path} key={idx}>
										<POS>
											<route.component />
										</POS>
									</Route>
								);
							case 'auth':
								return (
									<Route exact path={route.path} key={idx}>
										<Auth>
											<route.component />
										</Auth>
									</Route>
								);
						}
					})}
					<Redirect from='/' to='/auth/login' />
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
