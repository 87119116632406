import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getUserDetail } from "../store/actions/authActions";

import PurchaseHistoryTable from "../components/PurchaseHistoryTable";

const UserDetails = ({
  match: {
    params: { user_id = "" },
  },
}) => {
  const [details, setDetails] = useState({ status: "idle", data: null });
  const token = useSelector((state) => state.authUser.uid);

  useEffect(() => {
    setDetails({ status: "requested", data: null });
    getUserDetail(
      user_id,
      token,
      (data) => {
        setDetails({ status: "success", data });
      },
      () => {
        setDetails({ status: "failed", data: null });
      }
    );
  }, [user_id, token]);

  const profileDetails = useMemo(() => {
    if (!details.data) return null;
    return details.data.user_details;
  }, [details.data]);

  const purchaseDetails = useMemo(() => {
    console.log(details);
    if (!details.data?.purchaes?.length) return [];
    return details.data.purchaes.map((item) => item.purchase_data);
  }, [details.data]);

  return (
    <div className="payment__container">
      {details.status === "requested" ? <h3>Loading...</h3> : null}
      {details.status === "failed" ? <h3>Data could not be fetched.</h3> : null}
      {details.status === "success" ? (
        <>
          <div className="user__data">
            <div className="user__info">
              <div className="user__data--name">
                {profileDetails?.name || <i>Name not set.</i>}
              </div>
              <div className="user__data--email">
                <b>Email: </b>
                {profileDetails?.email || <i>Email not set.</i>}
              </div>
              <div className="user__data--mobile">
                <b>Mobile: </b>
                {profileDetails?.mobile || <i>Mobile number not set.</i>}
              </div>
            </div>
          </div>
          <div className="user__purchase">
            <PurchaseHistoryTable purchaseHistory={purchaseDetails} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default withRouter(UserDetails);
