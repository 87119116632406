import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

const UserProfileHeader = ({ toggleOpen, toggleClearOpen }) => {
	const { userProfile } = useSelector((state) => ({
		userProfile: state.authUser.userDetails,
	}));

	const userHasData = useMemo(() => {
		return userProfile.name?.length || userProfile.mobile?.length;
	}, [userProfile.name, userProfile.mobile]);

	return (
		<>
			<div className={`cart__item d-flex align-items-center justify-content-between p-3 mt-2`}>
				<div className="d-flex">
					{userHasData ? <i className="fa fa-user-circle mt-1 text-muted"></i> : null}
					<NavLink className="user_name_link" to={`/user-details/${userProfile.id}`}>
						<p className="user__name mb-0 ms-2">{userProfile.name?.length ? userProfile.name : userProfile.mobile}</p>
					</NavLink>
				</div>
				<div className="d-flex flex-row gap-3">
					{userHasData ? <i className="ms-auto mt-1 fa fa-edit text__primary pointer" onClick={toggleOpen}></i> : null}
				</div>
			</div>
		</>
	);
};

export default UserProfileHeader;
