import React, { useEffect, useState } from "react";
import { Form, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import { AwesomeButton } from "react-awesome-button";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import "react-awesome-button/dist/themes/theme-blue.css";
import {
  couponValidity,
  fetchCouponsList,
  skipCoupon,
} from "../store/actions/discoverActions";
import { notifyError, notifySuccess } from "../utils/notifyToast";

function ApplyCoupon(props) {
  let { isOpen, toggleOpen } = props;
  const dispatch = useDispatch();

  const {
    token,
    userDetails,
    couponValidityStatus,
    couponFetchStatus,
    couponList,
  } = useSelector(({ authUser, discover }) => ({
    token: authUser.uid,
    userDetails: authUser.userDetails,
    couponValidityStatus: discover.couponValidityStatus,
    couponList: discover.couponList,
    couponFetchStatus: discover.couponFetchStatus,
  }));

  const [localCouponData, setLocalCouponData] = useState({
    code: "",
    coupon: null,
  });
  const [cookies, setCookie] = useCookies("cart");

  useEffect(() => {
    if (!couponList?.length) dispatch(fetchCouponsList(token));
    // eslint-disable-next-line
  }, []);

  const updateUserCouponData = (field, value) => {
    setLocalCouponData({ ...localCouponData, [field]: value });
  };

  const onCouponSubmit = () => {
    if (!localCouponData.code.length) return;

    const _coupon = couponList?.find(
      (cpn) => cpn.name === localCouponData.code
    );

    if (!_coupon) {
      notifyError("Invalid coupon code.");
      return;
    }

    const newCartData = {
      packages: cookies.cart?.packages || [],
      coupon: _coupon.name,
    };

    setCookie("cart", newCartData, { path: "/" });

    dispatch(
      couponValidity(newCartData, userDetails.id, token, () => {
        notifySuccess("Coupon added.");
        toggleOpen();
      })
    );
  };

  const onSkip = () => {
    toggleOpen();
    dispatch(skipCoupon());
  };

  return (
    <>
      <Modal centered isOpen={isOpen} toggle={toggleOpen} size="lg" scrollable>
        <ModalHeader toggle={toggleOpen}>Apply Coupon</ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <Label className="mt-2">Coupon Code</Label>
            <Input
              value={localCouponData.code}
              className="mb-2"
              onChange={(e) => {
                updateUserCouponData("code", e.target.value);
              }}
              readOnly
              placeholder="XXXXXXXXXX"
              type="text"
              required
            />

            <div className="coupon__button-group">
              <button className="header_menu__item mt-3 w-50" onClick={onSkip}>
                Skip
              </button>

              <button
                className="header_menu__item mt-3 w-50"
                onClick={onCouponSubmit}
              >
                {couponValidityStatus === "requested" ||
                couponFetchStatus === "requested" ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : null}
                Submit
              </button>
            </div>

            <p className="mt-3 ">
              <b>Available coupons codes</b>
            </p>
            <div className="mb-2 coupon__field">
              {couponList?.length
                ? couponList.map(
                    (cpn, index) =>
                      cpn.enable_for_pos === "1" &&
                      cpn.enable === "1" && (
                        <div
                          key={index}
                          className={`coupon__item ${
                            localCouponData.code === cpn.name ? "active" : ""
                          }`}
                          onClick={() => {
                            updateUserCouponData("code", cpn.name);
                          }}
                        >
                          <span>{cpn.name}</span>
                        </div>
                      )
                  )
                : null}
            </div>

            <div className="coupon__button-group">
              <button className="header_menu__item mt-3 w-50" onClick={onSkip}>
                Skip
              </button>
              <button
                className="header_menu__item mt-3 w-50"
                onClick={onCouponSubmit}
              >
                {couponValidityStatus === "requested" ||
                couponFetchStatus === "requested" ? (
                  <div class="spinner-border text-light" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                ) : null}
                Submit
              </button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default ApplyCoupon;
