import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { FETCH_SHIFT_DATA } from "../../store/types";
import {
  shiftSession,
  getShiftData,
} from "../../store/actions/discoverActions";

const ShiftModal = ({ isOpen, toggleOpen, setCookies }) => {
  const dispatch = useDispatch();
  const { shiftData, token } = useSelector(({ discover, authUser }) => ({
    shiftData: discover.shiftData,
    token: authUser.uid,
  }));

  const startShift = async () => {
    var shiftValue;
    await shiftSession(token, "shift_start", (response) => {
      localStorage.setItem(
        "shift",
        response.shift_session_number
          ? response.shift_session_number
          : response.shift_session.shift_session_number
      );
      shiftValue = response;
      toggleOpen();
    });
    if (shiftValue.shift_session) {
      dispatch({
        type: FETCH_SHIFT_DATA,
        payload: { ...shiftData, shift_session: shiftValue.shift_session },
      });
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem("shift"))
        dispatch(getShiftData(token, "checkForErr", (res) => console.log(res)));
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Modal centered isOpen={isOpen} toggle={toggleOpen} size="md">
      <ModalBody className="px-5 py-4">
        <h4 className="mb-4">Are you sure you want to start shift?</h4>

        <div className="d-flex gap-2 cart__clear-buttons">
          <button
            className="header_menu__item w-50"
            style={{ backgroundColor: "red" }}
            onClick={toggleOpen}
          >
            Cancel
          </button>
          <button className="header_menu__item w-50" onClick={startShift}>
            Start Shift
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ShiftModal;
