import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDayClosureHistoryData } from "../store/actions/discoverActions";
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";

function DayClosureHistory() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [dayClosureHistoryData, setDayClosureHistoryData] = useState();
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));

  const dayClosureHistoryColumns = (onDetailSeek) => [
    {
      name: "DayClosure Id",
      cell: (row) => row.day_closure_id,
    },
    {
      name: "Created at",
      cell: (row) => row.date_created,
    },
    {
      name: "",
      cell: (row) => (
        <button
          className="header_menu__item"
          style={{ margin: ".5rem" }}
          onClick={() => onDetailSeek(row)}
        >
          Details
        </button>
      ),
    },
  ];

  useEffect(() => {
    getDayClosureHistoryData(token, setLoading, (response) => {
      const sortedData = response.data.sort(
        (a, b) => new Date(b.date_created) - new Date(a.date_created)
      );
      setDayClosureHistoryData(sortedData);
    });
  }, []);

  const onDetailSeek = (row) => {
    history.push("/day-closure-history/" + row.day_closure_id);
  };

  return (
    <div className="m-5 pb-5">
      <h2 className="pb-2">Day Closure History </h2>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "15%",
          }}
        >
          <Spinner className="package-spinner" />
        </div>
      ) : (
        <DataTable
          highlightOnHover
          onRowClicked={(row) => onDetailSeek(row)}
          columns={dayClosureHistoryColumns(onDetailSeek)}
          data={dayClosureHistoryData}
        />
      )}
    </div>
  );
}

export default DayClosureHistory;
