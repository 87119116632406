import InfiniteCalendar from "react-infinite-calendar";
import moment from "moment";

function Calendar(props) {
  const {
    handleDateChange,
    selectedDate,
    minDate,
    maxDate,
    disabled_dates,
    disabled_weeks,
  } = props;
  const curDate = new Date();

  let startDate = minDate;

  if (
    moment(moment(curDate).format("YYYY-MM-DD")).isAfter(
      moment(minDate).format("YYYY-MM-DD"),
      "day"
    ) ||
    !startDate
  ) {
    startDate = curDate;
  }

  return (
    <>
      <div style={{ backgroundColor: "white" }} className="time-slots">
        {moment(moment(maxDate).format("YYYY-MM-DD")).isSameOrAfter(
          moment(curDate).format("YYYY-MM-DD"),
          "day"
        ) && maxDate != "" ? (
          <InfiniteCalendar
            displayOptions={{
              showHeader: false,
            }}
            width={"100%"}
            height={300}
            min={startDate}
            minDate={startDate}
            max={maxDate}
            maxDate={maxDate}
            // disabledDates={disabled_dates} // disabling 24 hours check in POS devices
            // disabledDays={disabled_weeks} // disabling 24 hours check in POS devices
            selected={selectedDate}
            onSelect={handleDateChange}
            disablePast
            theme={{
              selectionColor: function (date) {
                return "#ec6d59";
              },
            }}
          />
        ) : (
          <div>Event is completely booked</div>
        )}
      </div>
    </>
  );
}

export default Calendar;
