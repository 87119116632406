import { combineReducers } from 'redux';
import authReducer from './authReducer';
import authUser from './authUser';
import discoverReducer from './discoverReducer';

export let rootReducer = combineReducers({
	auth: authReducer,
	authUser: authUser,
	discover: discoverReducer,
});

export default rootReducer;
