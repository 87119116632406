import React, { useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Label,
} from "reactstrap";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import CartBodyList from "./CartBodyList";
import {
  bookPackages,
  cancelBooking,
  initiatePayment,
  authorizePayLater,
} from "../store/actions/discoverActions";
import UserProfileHeader from "./UserProfileHeader";
import ClearCartModal from "./ClearCartModal";
import UserDetails from "./UserDetails";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { notifySuccess, notifyWarn } from "../utils/notifyToast";

function BookingModal(props) {
  const [cookies, setCookie] = useCookies(["cart"]);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isClearOpen, setIsClearOpen] = useState(false);
  const [bookingMethod, setBookingMethod] = useState("");
  const [notes, setNotes] = useState("");
  const [bookingMethodNotes, setBookingMethodNotes] = useState("");
  const [paymentJSON, setPaymentJSON] = useState([]);
  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const toggleClearOpen = () => {
    setIsClearOpen(!isClearOpen);
  };

  const { couponValidityData, bookingSequence, token, userDetails } =
    useSelector(({ authUser, discover }) => ({
      token: authUser.uid,
      userDetails: authUser.userDetails,
      bookingSequence: discover.bookingSequence,
      couponValidityData: discover.couponValidityData,
    }));

  const dispatch = useDispatch();
  const history = useHistory();

  const cartItemCount = useMemo(() => {
    return cookies.cart?.packages?.length || 0;
  }, [cookies.cart?.packages]);

  const total = useMemo(() => {
    if (!cookies.cart?.packages?.length) return 0;
    let _total = 0;
    for (let pack of cookies.cart.packages) _total += pack.total;
    return Number(_total);
  }, [cookies.cart?.packages]);

  const couponDiscountAmount = useMemo(() => {
    if (!couponValidityData?.length) return 0;
    const deductedTotalValue = couponValidityData
      .map((cpnPkg) => cpnPkg.total || 0)
      .reduce((prev, curr) => prev + curr, 0);
    return total - Number(deductedTotalValue);
  }, [couponValidityData, total]);

  const onBookingCancelled = () => {
    dispatch(cancelBooking());
  };

  useEffect(() => {
    if (bookingSequence === "in-progress")
      authorizePayLater(userDetails.id, token, (authorizeResponse) => {
        var paymentValues = [
          { title: "CARD", value: "card_manual" },
          { title: "CASH", value: "cash" },
          { title: "BANK TRANSFER", value: "bank-transfer" },
          { title: "CHEQUE", value: "cheque" },
          { title: "CUSTOM PAYMENTS", value: "custom-payments" },
        ];
        if (authorizeResponse) {
          setPaymentJSON([
            ...paymentValues,
            { title: "PAY LATER", value: "paylater" },
          ]);
        } else {
          setPaymentJSON(paymentValues);
        }
      });
  }, [bookingSequence]);

  const onBookingSubmit = () => {
    if (cookies.cart?.packages.length === 0) {
      notifyWarn("Please add items to cart");
    } else if (!paymentMethod) {
      notifyWarn("Please choose payment method");
    } else {
      dispatch(
        bookPackages(
          notes,
          cookies.cart,
          bookingMethod,
          bookingMethodNotes,
          userDetails.id,
          token,
          (purchaseResponse) => {
            dispatch(
              initiatePayment(
                localStorage.getItem("shift"),
                purchaseResponse,
                token,
                paymentMethod,
                (paymentResponse) => {
                  window.open(
                    paymentResponse.payment_link,
                    "Payment - Discover POS",
                    `left=100,top=100,popup=yes`
                  );
                  notifySuccess("Order(s) booked.");
                  setCookie(
                    "cart",
                    {
                      packages: [],
                      coupon_id: null,
                    },
                    { path: "/" }
                  );
                  history.push(
                    `/purchase-details/${purchaseResponse.purchase_number}`
                  );
                }
              )
            );
          }
        )
      );
    }
  };

  return (
    <div>
      <Modal centered size="xl" isOpen={bookingSequence === "in-progress"}>
        <ModalHeader>Book Packages</ModalHeader>
        <ModalBody>
          <div className="booking__details">
            <div className="cart__list">
              <CartBodyList booking />
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <Label className="mt-2">Business Nature/Booking Method</Label>
                <Input
                  style={{ width: 500 }}
                  value={bookingMethod}
                  type="select"
                  onChange={(e) => setBookingMethod(e.target.value)}
                >
                  {[
                    "",
                    "Walk-in",
                    "Social Media",
                    "Email/Direct/Telephone",
                    "Website",
                  ].map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Input>
                {bookingMethod && (
                  <Input
                    value={bookingMethodNotes}
                    placeholder="Booking Method Extra Info - Notes"
                    onChange={(e) => {
                      setBookingMethodNotes(e.target.value);
                    }}
                    type="text"
                    className="mt-2"
                    style={{ width: 500 }}
                  />
                )}
              </div>
            </div>
            <div className="booking__summary">
              <div className="content">
                <h4>Booking Summary</h4>
                <UserProfileHeader
                  toggleClearOpen={toggleClearOpen}
                  toggleOpen={toggleOpen}
                />
                <hr />
                {couponValidityData?.length ? (
                  <>
                    <div className="coupon__applied">
                      <span>Coupon applied.</span>
                      <span className="coupon__code">
                        {couponValidityData[0]?.coupon_name || ""}
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="amount__data">
                  <div className="packages__count">
                    <span>
                      {cartItemCount > 1 ? "Package(s):" : "Package: "}
                    </span>
                    <b>{cartItemCount}</b>
                  </div>
                  <div className="initial__total">
                    <span>Total:</span>
                    <b>AED {total}</b>
                  </div>
                  <div className="coupon__discount">
                    <span>Coupon Discount:</span>
                    <b>AED {couponDiscountAmount}</b>
                  </div>
                  <div className="net__total">
                    <span>Net Total:</span>
                    <b>AED {total - couponDiscountAmount}</b>
                  </div>
                </div>
              </div>
              <div className="payment-method">
                {paymentJSON.map((payment) => (
                  <label className="payment-card">
                    <input
                      name="plan"
                      className="radio"
                      type="radio"
                      value={payment.value}
                      onClick={(e) => setPaymentMethod(e.target.value)}
                    />

                    <span className="payment-details">
                      <span className="payment-type">{payment.title}</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="payment-icon"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                  </label>
                ))}
              </div>
              <Input
                placeholder="Internal Notes"
                value={notes}
                onChange={(e) => {
                  setNotes(e.target.value);
                }}
                type="text"
                style={{ marginTop: "1rem", marginBottom: "1rem" }}
                required
              />
              <div style={{ display: "flex", gap: ".5rem" }}>
                <button
                  className="header_menu__item w-50"
                  style={{ backgroundColor: "red" }}
                  onClick={onBookingCancelled}
                >
                  Cancel Booking
                </button>
                <button
                  style={{
                    backgroundColor:
                      (!cartItemCount || !localStorage.getItem("shift")) &&
                      "#C0C0C0",
                    cursor:
                      (!cartItemCount || !localStorage.getItem("shift")) &&
                      "revert",
                  }}
                  className="header_menu__item w-50"
                  onClick={onBookingSubmit}
                  disabled={!cartItemCount || !localStorage.getItem("shift")}
                >
                  Confirm Booking
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ClearCartModal isOpen={isClearOpen} toggleOpen={toggleClearOpen} />
      <UserDetails isOpen={isOpen} toggleUserDetails={toggleOpen} />
    </div>
  );
}

export default BookingModal;
