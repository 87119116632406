import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";

const promotionDataTableModel = [
	{
		name: "Coupon Code Used",
		cell: (row) => row.code,
	},
	{
		name: "Discount",
		cell: (row) => <span>AED {row.value}</span>,
	},
];

const PaymentPromotionData = ({ coupon, orderData = [] }) => {
	const couponList = useSelector((state) => state.discover.couponList);

	const couponData = useMemo(() => {
		if (!coupon?.length) return null;
		if (!couponList?.length) return null;
		return couponList.find((item) => Number(item.id) === Number(coupon));
	}, [couponList, coupon]);

	const discountValue = useMemo(() => {
		if (!orderData.length) return 0;
		return orderData.reduce((prev, curr) => prev + Number(curr.deducted_value || "0"), 0);
	}, [orderData]);

	return couponData ? (
		<div className="promotion__data">
			<h5>Promotion Data</h5>
			<DataTable data={[{ code: couponData.name, value: discountValue }]} columns={promotionDataTableModel} />
		</div>
	) : null;
};

export default PaymentPromotionData;
