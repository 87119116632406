import { Button } from "reactstrap";
import React, { useEffect, useState } from "react";
import { Col, Container, Row, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { favoriteDetail } from "../store/actions/authActions";
import PackageDetail from "../components/PackageDetail";
import {
  ArrowLeft,
  FavoriteIcon,
} from "../components/PackageDetail/SvgComponentss";
import { useDispatch, useSelector } from "react-redux";
import RadioInput from "../components/PackageDetail/RadioInput";

function ProductDetails() {
  const history = useHistory();
  const [favAvailable, setFavAvailable] = useState(false);
  const [changePriceValue, setChangePriceValue] = useState();
  const { packages } = useSelector((state) => state.discover);
  const packageName = useSelector((state) => state.discover);
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));
  const { packageDetails } = useSelector((state) => ({
    packageDetails: state.discover.package,
  }));
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (packageDetails?.pos_custom_prices) {
  //     setChangePriceValue(JSON.parse(packageDetails.pos_custom_prices)[0]);
  //   }
  // }, [packageDetails]);

  useEffect(async () => {
    setFavAvailable(
      packages.filter(
        (fav) => fav.id === packageName.package?.id && fav.favorite
      ).length > 0
    );
  }, [packages]);

  const handleFavorite = (action) => {
    dispatch(
      favoriteDetail(
        packageName.package?.id,
        action,
        favAvailable,
        token,
        setFavAvailable
      )
    );
  };

  return (
    <>
      <Container className="mt-4 mb-5">
        <Button
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            history.push("/pos/DiscoverMleiha");
          }}
        >
          <ArrowLeft />
        </Button>
        <span style={{ marginLeft: "2%", fontSize: 24 }}>
          {packageName.package?.package_name}
        </span>
        <Button
          color="primary"
          onClick={() =>
            handleFavorite(
              favAvailable ? "removeFavouritePackage" : "addFavouritePackage"
            )
          }
          style={{ float: "right" }}
        >
          <FavoriteIcon />
          <span style={{ marginLeft: "0.5rem" }}>
            {favAvailable ? "Remove from Favorite" : "Add to Favorite"}
          </span>
        </Button>
        {packageDetails?.pos_custom_prices && (
          // <Input
          //   style={{ width: 300, marginTop: "10px" }}
          //   type="select"
          //   onChange={(e) =>
          //     setChangePriceValue(
          //       JSON.parse(packageDetails.pos_custom_prices).find(
          //         (price) => price.id === e.target.value
          //       )
          //     )
          //   }
          // >
          //   <option key={0} value={0}>
          //     default
          //   </option>
          //   {JSON.parse(packageDetails.pos_custom_prices).map((item) => (
          //     <option key={item.id} value={item.id}>
          //       {item.name}
          //     </option>
          //   ))}
          // </Input>
          <div style={{ marginTop: "1rem" }}>
            <Button
              style={{ marginRight: "1rem" }}
              color="primary"
              onClick={(e) =>
                setChangePriceValue(
                  JSON.parse(packageDetails.pos_custom_prices).find(
                    (price) => !price.id
                  )
                )
              }
            >
              Default
            </Button>
            {JSON.parse(packageDetails.pos_custom_prices).map((item, id) => (
              // <RadioInput
              //   name="gender1"
              //   value={id}
              //   onChange={(e) =>
              //     setChangePriceValue(
              //       JSON.parse(packageDetails.pos_custom_prices).find(
              //         (price) => price.id === e.target.value
              //       )
              //     )
              //   }
              //   lable={item.name}
              // />
              <Button
                style={{ marginRight: "1rem" }}
                color="primary"
                onClick={(e) =>
                  setChangePriceValue(
                    JSON.parse(packageDetails.pos_custom_prices).find(
                      (price) => Number(price.id) === id + 1
                    )
                  )
                }
              >
                {item.name}
              </Button>
            ))}
          </div>
        )}
        <Row>
          <Col lg="12" className="mt-3">
            <PackageDetail changePriceValue={changePriceValue} />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductDetails;
