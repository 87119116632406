import React from "react";
import { Modal, ModalBody } from "reactstrap";

const DeleteCartItemModal = ({ isOpen, toggleOpen, onConfirm }) => {
  const onClear = () => {
    onConfirm && onConfirm();
  };

  const onCancel = () => {
    toggleOpen();
  };

  return (
    <Modal
      unmountOnClose
      centered
      isOpen={isOpen}
      toggle={toggleOpen}
      size="md"
    >
      <ModalBody className="px-5 py-4">
        <h4 className="mb-4">
          Are you sure you want to delete this item from the cart?
        </h4>

        <div className="d-flex gap-2 cart__clear-buttons">
          <button
            className="header_menu__item mt-3 w-50"
            style={{ backgroundColor: "red" }}
            onClick={onCancel}
          >
            No, cancel!
          </button>
          <button className="header_menu__item mt-3 w-50" onClick={onClear}>
            Yes, delete from cart.
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteCartItemModal;
