import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Auth = props => {
    const { uid } = useSelector(state => state.authUser)
    const history = useHistory()
    if (uid) {
        history.push("/pos/DiscoverMleiha")
    }
    return (
        <>
            {props.children}
        </>
    );
};

export default Auth;
