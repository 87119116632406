import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Cart from '../components/Cart';
import Header from '../components/Header';
const Main = props => {
	const { uid } = useSelector(state => state.authUser);
	const history = useHistory();
	if (!uid) {
		history.push('/auth/login');
	}
	return (
		<>
			<Header />
			<div className='d-flex'>
				<div className='content__section'>
					<div className='content__section-child'>
						{props.children}
					</div>
				</div>
				<Cart />
			</div>
		</>
	);
};

export default Main;
