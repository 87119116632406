import { useMemo, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  initiatePayment,
  authorizePayLater,
} from "../store/actions/discoverActions";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { notifyInfo, notifyWarn } from "../utils/notifyToast";
import NewWindow from "react-new-window";
import logo from "../assets/images/mleiha-logo.jpeg";
import QRCode from "react-qr-code";

const PaymentUserData = ({
  userData,
  status,
  purchaseData,
  orderData,
  purchaseResponse,
}) => {
  const { token, userDetails } = useSelector(({ authUser }) => ({
    token: authUser.uid,
    userDetails: authUser.userDetails,
  }));
  const windowRef = useRef();
  const [openPayMethodModal, setOpenPayMethodModal] = useState(false);
  const [openWindow, setOpenWindow] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();

  const [paymentJSON, setPaymentJSON] = useState([]);

  const getStatus = useMemo(() => {
    if (status === "1") return "success";
    if (status === "2") return "failed";
    return "pending";
  }, [status]);

  const onRetryPayment = () => {
    if (!paymentMethod) {
      notifyWarn("Please choose payment method");
      return;
    }
    notifyInfo("Initiating Payment retry.");
    setOpenPayMethodModal(false);
    dispatch(
      initiatePayment(
        localStorage.getItem("shift"),
        purchaseResponse,
        token,
        paymentMethod,
        (paymentResponse) => {
          window.open(
            paymentResponse.payment_link,
            "Payment - Discover POS",
            `left=100,top=100,popup=yes,width=800px,height=800px`
          );
        }
      )
    );
  };

  useEffect(() => {
    if (openPayMethodModal)
      authorizePayLater(userDetails.id, token, (authorizeResponse) => {
        var paymentValues = [
          { title: "CARD", value: "card_manual" },
          { title: "CASH", value: "cash" },
          { title: "BANK TRANSFER", value: "bank-transfer" },
          { title: "CHEQUE", value: "cheque" },
          { title: "CUSTOM PAYMENTS", value: "custom-payments" },
        ];
        if (authorizeResponse) {
          setPaymentJSON([
            ...paymentValues,
            { title: "PAY LATER", value: "paylater" },
          ]);
        } else {
          setPaymentJSON(paymentValues);
        }
      });
  }, [openPayMethodModal]);

  useEffect(() => {
    if (openWindow && windowRef.current) {
      setTimeout(() => {
        windowRef.current.window.print();
      }, 300);
    }
  }, [openWindow]);

  console.log(orderData);

  return (
    <div className="user__data">
      {openWindow && (
        <NewWindow ref={windowRef} onUnload={() => setOpenWindow(false)}>
          <div
            style={{
              width: "300px",
              margin: "5px auto",
              paddingLeft: "5px",
              paddingRight: "5px",
              textAlign: "center",
              fontFamily: "monospace",
            }}
          >
            <img
              style={{
                maxWidth: "80%",
                width: "auto",
                height: "auto",
                maxHeight: "100px",
              }}
              src="https://i.postimg.cc/K8W0m7vc/image-12.png"
            />
            <br />
            <h5
              style={{
                textAlign: "center",
                margin: "4px",
                fontSize: 18,
                fontWeight: "bold",
              }}
            >
              Mleiha Archaeological Centre
            </h5>
            <strong style={{ textAlign: "center" }}>
              Sharjah Investment and
            </strong>
            <br />
            <strong style={{ textAlign: "center" }}>
              and development Authority Shurooq
            </strong>
            <h3
              style={{
                textAlign: "center",
                margin: "4px",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Tax Invoice
            </h3>

            <span style={{ fontSize: 15, fontWeight: "bold" }}>
              TRN NO: 100391298500003
            </span>
            <br />

            {/* <h5
              style={{
                textAlign: "center",
                margin: "2px",
                fontSize: 15,
                fontWeight: "bold",
              }}
            >
              Slip : {orderData[0].purchase_number}
            </h5> */}
            <span>Staff : </span>
            <br />
            <span>Date : {orderData[0].created_at}</span>
            <br />
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <b>Description</b>
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <b>Amount</b>
                  </td>
                </tr>
                {orderData.map((order) => (
                  <>
                    <tr>
                      <td></td>
                      <td>
                        {order.package_name} - Adult:
                        {order.adult_qty} | Child:
                        {order.child_qty}
                      </td>
                      <td style={{ whiteSpace: "nowrap" }}>
                        {order.sub_total || order.total} AED
                      </td>
                    </tr>
                    <hr />
                  </>
                ))}
                <hr />
                <tr>
                  <td></td>
                  <td>Discount</td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    -
                    {orderData.reduce(
                      (sum, order) => Number(order.deducted_value) + sum,
                      0
                    )}{" "}
                    AED
                  </td>
                </tr>
                <hr />
                <tr>
                  <td></td>
                  <td>Total AED </td>
                  <td style={{ whiteSpace: "nowrap" }}>
                    {purchaseData.total} AED
                  </td>
                </tr>
                <hr />
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">
                    <b>Member Name : </b>
                  </td>
                  <td>
                    <b>{userDetails.name}</b>
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <b>Phone : </b>
                  </td>
                  <td>
                    <b>{userDetails.mobile}</b>
                  </td>
                </tr>
              </tbody>
            </table>
            <strong style={{ textAlign: "center" }}>Thank You!</strong>
            <br />
            <strong style={{ textAlign: "center" }}>Enjoy your visit</strong>
            <br />
            <strong style={{ textAlign: "center" }}>
              Hope to see you again!
            </strong>
            <br />
            <span>No refunds on purchases made</span>
            <br />
            <strong style={{ textAlign: "center", marginTop: "1rem" }}>
              Terms and Conditions
            </strong>
            <br />
            <table>
              <tbody>
                <tr>
                  <td colSpan="2">
                    * <b>Standard booking, cancellation and</b>
                    <br />
                    <b style={{ marginLeft: "15px" }}>
                      operational policies apply.
                    </b>
                    <br />* <b>No cash or credit card refunds.</b>
                    <br />* <b>Please retain this receipt as proof of</b>
                    <br />
                    <b style={{ marginLeft: "15px" }}>ticket purchase.</b>
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {orderData.map((order) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <QRCode
                    size={50}
                    style={{
                      height: "auto",
                      maxWidth: "100px",
                      width: "100px",
                    }}
                    value={order.order_number}
                    viewBox={`0 0 50 50`}
                  />
                  <div style={{ fontSize: 12 }}>{order.package_name}</div>
                  <div style={{ fontSize: 12 }}>{order.order_number}</div>
                </div>
              ))}
              <strong style={{ textAlign: "center" }}>
                If you enjoyed your visit please leave us a review on
              </strong>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "1rem",
                  gap: "2rem",
                }}
              >
                <div>
                  <QRCode
                    size={50}
                    style={{
                      height: "auto",
                      maxWidth: "70px",
                      width: "70px",
                    }}
                    value={"https://www.google.com/"}
                    viewBox={`0 0 50 50`}
                  />
                  <div style={{ fontSize: 12 }}>Google Review</div>
                </div>
                <div>
                  <QRCode
                    size={50}
                    style={{
                      height: "auto",
                      maxWidth: "70px",
                      width: "70px",
                    }}
                    value={"https://www.tripadvisor.com/"}
                    viewBox={`0 0 50 50`}
                  />
                  <div style={{ fontSize: 12 }}>Trip Advisor</div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ pageBreakAfter: "always" }}></div>
        </NewWindow>
      )}
      <div className="user__info">
        <div className="user__data--name">{userData.name}</div>
        <div className="user__data--email">
          <b>Email: </b>
          {userData.email}
        </div>
        <div className="user__data--mobile">
          <b>Mobile: </b>
          {userData.mobile}
        </div>
      </div>
      <div className="status" style={{ display: "flex", gap: "1rem" }}>
        {getStatus === "failed" || getStatus === "pending" ? (
          <button
            className="header_menu__item"
            onClick={() => setOpenPayMethodModal(true)}
          >
            Retry Payment
          </button>
        ) : null}
        {getStatus === "success" ? (
          <button
            className="header_menu__item"
            onClick={() => setOpenWindow(true)}
          >
            Print Invoice
          </button>
        ) : null}
        {getStatus === "pending" ? (
          <>
            <div className={`purchase__status ${getStatus}`}>
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>{" "}
              Waiting for payment
            </div>
          </>
        ) : null}
        {getStatus === "success" ? (
          <div className={`purchase__status ${getStatus}`}>{getStatus}</div>
        ) : null}
      </div>
      <Modal
        centered
        isOpen={openPayMethodModal}
        toggle={() => setOpenPayMethodModal(!openPayMethodModal)}
        size="lg"
        scrollable
      >
        <ModalHeader toggle={() => setOpenPayMethodModal(!openPayMethodModal)}>
          Choose Payment Method
        </ModalHeader>
        <ModalBody>
          <div className="payment-method" style={{ width: "100%" }}>
            {paymentJSON.map((payment) => (
              <label className="payment-card">
                <input
                  name="plan"
                  className="radio"
                  type="radio"
                  value={payment.value}
                  onClick={(e) => setPaymentMethod(e.target.value)}
                />

                <span className="payment-details">
                  <span className="payment-type">{payment.title}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="payment-icon"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </label>
            ))}
          </div>
          <button
            className="header_menu__item w-40"
            style={{ marginLeft: "auto" }}
            onClick={onRetryPayment}
          >
            Confirm Payment
          </button>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PaymentUserData;
