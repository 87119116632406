import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Spinner } from "reactstrap";
import { getDayClosureDetails } from "../store/actions/discoverActions";
import DataTable from "react-data-table-component";
import { useHistory, useParams } from "react-router-dom";

function DayClosureHistoryDetails() {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [dayClosureDetails, setDayClosureDetails] = useState();
  const { token } = useSelector(({ authUser }) => ({
    token: authUser.uid,
  }));

  useEffect(() => {
    getDayClosureDetails(
      setLoading,
      token,
      params.day_closure_id,
      (response) => {
        setDayClosureDetails(response);
      }
    );
  }, []);

  return (
    <>
      <div className="m-5 pb-5">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15%",
            }}
          >
            <Spinner className="package-spinner" />
          </div>
        ) : (
          <>
            {dayClosureDetails && (
              <div
                className="dayclosure__table"
                dangerouslySetInnerHTML={{
                  __html: dayClosureDetails,
                }}
              ></div>
            )}
          </>
        )}
        <button
          className="header_menu__item"
          style={{ marginTop: "1rem", backgroundColor: "red" }}
          onClick={() => history.goBack(-1)}
        >
          Back
        </button>
      </div>
    </>
  );
}

export default DayClosureHistoryDetails;
